  .carouselContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vw;
    width: 46vw;
    margin-right: 1vw;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: 15vw;
    width: 46vw;
    padding-top: .5vw;

  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    border-radius: 1vw;
    background-color: rgba(30, 30, 30, 0.6); 
    height: 14vw;
    width: 45vw;
    padding: .5vw;
  }

  .card:hover{
    background-color: rgba(30, 30, 30, 0.9); 
  }

  .cardTitleBox {
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw; 
  }
  
  .cardTitle {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 1.7vw;
    margin-top: .5vw;
    font-family: Helvetica;
  }
  
  .cardContent {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 1vw;
    flex-grow: 1; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    text-align: left;
    margin: 1vw 0;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .readMoreButton {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: .7vw;
    border: none;
    margin: .5vw;
    cursor: pointer;
    border: 1px solid white;
    border-radius: .5vw;
    padding: .2vw;;
    background-color: transparent;
  }

  .readMoreButton:hover {
    color: #FFCF00;
    border: 1px solid #FFCF00;
  }

  .downloadButton {
    color: white;
    margin: .5vw;
    cursor: pointer;
    font-size: 2vw;
  }

  .downloadButton:hover {
    color: #FFCF00
  }
  
  .topNav, .bottomNav {
    cursor: pointer;
    text-align: center;
    color: white
  }

  .topNav:hover, .bottomNav:hover {
    color: #FFCF00
  }