.body {
    margin-top: 2vw;
    display: flex;
    flex-direction: row;
    line-height: normal;
}

.bigText{
    width: 100%;
    font-family: Helvetica;
    font-size: 5vw;
    color: white;
    margin-bottom: 3vw
}

.smallText{
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: Helvetica;
    font-size: 1.4vw;
    line-height: 1.75;
    color: white;
}
