
.descriptionText{
    padding-left: 12%;
    padding-right: 12%;
    font-size: 1.2vw;
    font-weight: 500;
    margin-bottom: 2vw;
    margin-top: 2vw;
    color: #1b365f;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vw;
}

.buttonContainer button{
    width: 18vw;
    height: 3vw;
    border-radius: 0.5rem;
    border: none;
    background-color: #8B5A73;
    color: #fff;
    font-size: 1.0vw;
    font-weight: 700;
    cursor: pointer;
    margin: 2vw;
    transition: transform 0.2s ease-in-out;
}

.buttonContainer button:hover {
    transform: scale(1.05); /* Scale the button on hover */
    background-color: #784E63;
}