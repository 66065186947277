.mainContainer {
    margin-top: 3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; 
}

.cardContainer {
    transition: transform 0.4s ease; 
    width: 18vw;
    height: 20vw;
    border-radius: 1vw;
    background-color: rgba(30, 30, 30, 0.7); 
}

.textContainer {
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top:.5vw;
    height: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardContainer:hover {
    transform: scale(1.1);
}

.cardLink {
    font-weight: bold;
    color: #1f2937;
    text-decoration: none;
}

.pressHeader{
    font-size: 4vw;
    font-family: "Helvetica";
    color: white;
    text-align: center;
    margin-top: 1vw;
}

.pressOneLiner{
    font-size: 1.3vw;
    font-family: "Helvetica";
    color: white;
    text-align: center;
}

.cardTitle {
    font-size: 1.2vw;
    font-weight: bold;
    line-height: tighter;
    color: white;
}

.cardText {
    margin-top: .2vw;
    font-size: .9vw;
    font-weight: normal;
    color: white;
}

.cardImage {
    width: 100%;
    height: 8vw;
    border-radius: 1vw 1vw 0 0;
}