.buttonColored {
    color: white;
    font-weight: 500;
    border: 0;
    background-color: #8B5A73;
    width: 10%;
    margin-bottom: 1vw;
    border-radius: 3px;
  }
  
  .buttonColored:hover {
    background-color: #784E63;
    color: white;
  }
  
  .buttonColored:disabled {
    background-color: grey;
    color: black;
    opacity: 0.5;
  }
