.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
}

.fullscreen-iframe {
    width: 100vw; /* Full width of the parent container */
    height: 100vh; /* Full height of the parent container */
    border: none; /* Optional: Removes the default border */
}

.whitepaperHeader{
    font-size: 4vw;
    font-family: "Helvetica";
    color: white;
    text-align: center;
}

.whitepaperOneLiner{
    font-size: 1.3vw;
    font-family: "Helvetica";
    color: white;
    text-align: center;
    margin-bottom: 3vw;
}

