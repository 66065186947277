.formContainer {
    margin-left: 3vw;
    width: 100%;
}

.formHeader{
    font-size: 3vw;
    font-weight: 600;
    margin-bottom: 1vw;
    margin-left: .5vw;
    color: white;
    text-align: left;
}

.formPrompt{
    color: white;
    font-family: Helvetica;
    font-size: 1.2vw;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 1vw;
    margin-left: .5vw;
}

.containerContactUs{
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
    align-items: center;
}

.input {
    color: black;
    background-color: white;
    width: 16vw; 
    border-radius: 1vw;
}

.inputWide {
    background-color: white;
    border-radius: 1vw;
    width: 33vw
}

.input:hover {
    background-color: white;
}

.box {
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 5vw; 
    color: white;
    font-size: 2vw;
}
