  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes sizeGrow {
    from { transform: scale(0.5); }
    to { transform: scale(1); }
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  .container {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    padding-left: 2%;
    padding-right: 2%;
  }

  .full-window-component{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .black-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, .9);
    padding-left: 15vw;
    padding-right: 15vw;
    min-height: 100vh;
    width:100%;
  }

  .content {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }

  .fade-out {
    animation: fadeOut 1s ease-in-out;
  }
  
  .header {
    color: white;
    font-family: Helvetica;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-shadow: 0px 4px 4px #00000040;
    border-bottom: 6px solid #FFCF00;
  }

  .subheader {
    margin-top: 15px; 
    color: #003eab;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: 0;
    line-height: normal;
    text-shadow: 0px 4px 4px #00000040;
  }
    
  .body {
    color: white;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
  }

  .bold-body {
    color: #1b365f;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .overlay-text {
    color: white;  
    font-size: 6vw; 
    font-family: 'Bebas Neue', sans-serif;
    text-align: center; 
    text-shadow: 8px 8px 8px black; 
    width: 100%; 
    padding: 0 2rem; 
    opacity: 0; 
    animation: fadeIn 1s ease-in-out 0.5s forwards, 
    sizeGrow 1s ease-in-out 0.5s forwards;
  }
