.techSubheader {
    color: #1b365f;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.3vw;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 3vw;
}

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2vw;
    border-bottom: 1px solid #1b365f;
    align-items: center;

}

.cardBody {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    padding-top: 1vw;
    padding-left: 2.5vw
}

.cardText {
    color: #1b365f;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1vw;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
}
  
.extraText {
    color: #1b365f;
    font-family: "Inter-Bold", Helvetica;
    font-size: .9vw;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: justify;
}

.readMoreButton{
    position: absolute;
    top: 0px;
    right: 0px;
}

.readMoreButton.MuiIconButton-root:hover {
    background-color: transparent;
    box-shadow: none; 
}

.cardImage{
    padding-right: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.oneLinerContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.expandedContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2vw;
}

.expandedCardBody{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 100%;
}

.linkBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    border-radius: 10px;
}
  
.linkText{
    color: #1b365f;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1vw;
    font-weight: bold;
    letter-spacing: 0;

    padding: .5vw;
    border-radius: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
    cursor: pointer;
    text-decoration: none
}

.icon{
    margin-left: .4vw;
    margin-bottom: .1vw;
    cursor: pointer;
    color: gray;
}

.image{
    height: 100%;
    width: 100%;
}

.orangeButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #F47E42;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    line-height: 1; /* Remove extra space below the text */
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 12vw;
}

.orangeButton:hover {
    background-color: #d16c39;
}