.carouselContainer {
    width: 70vw;
    margin-top: 2vw;
    align-items: center; /* Horizontally centers the content */
    justify-content: center;
}

.carouselCard {
    height: 100%; 
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
    transition: border 0.3s ease, background-color 0.3s ease; /* Added transition for background-color */
    position: relative;
    align-items: center;
    background-color: transparent; /* Ensures the background is initially transparent */
    border-radius: 2vw;
}
  
.carouselCard img {
    width: 100%; 
    height: 3.5vw;
    object-fit: fill; 
    transition: transform 0.3s ease, filter 0.3s ease; /* Added transition for filter */
    filter: brightness(0) invert(1); /* Makes the image white */
}

.carouselCard:hover {
    background-color: rgb(255, 255, 255); /* Adds an opaque gray background on hover */
}

.carouselCard:hover img {
    filter: none; /* Removes the filter on hover of the card */
}

.carouselCard a {
    display: block;
    height: 100%;
}

.description {
    width: 100%;
    font-family: Helvetica;
    font-size: 1vw;
    color: white;
    text-align: center; 
    padding: .5vw;
    align-content: center;
    margin-top: 5px;
}

.line{
    width: 30vw;
    height: .5vw;
    border-bottom: white 1px solid;
    margin: .5vw auto 0; /* Centers the line horizontally */
}
