  .carouselContainer {
    width: 80vw;
    padding: 1vw;
  }
  
  .carouselCard {
    display: flex;
    padding: 2vw;
    align-items: center;
    justify-content: center;
  }
  
  .carouselCard img {
    width: 11vw;
    height: 11vw;
    transition: transform 0.3s ease;
    align-items: center;
    justify-content: center;
  }

  .carouselCard:hover img {
    transform: scale(1.15);
    cursor: default;
  }

  .cardTitle {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    color: #1b365f;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
    margin-top: .7vw;
  }

  .descriptionContainer{
    height: 5vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .descriptionText{
    color: #1b365f;
    font-size: 1vw;
    text-align: center;
  }

  .imageContainer {
    width: 11vw; /* Same as your image width */
    height: 11vw; /* Same as your image height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 4vw
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #1b365f;
    animation: spin 1s ease infinite;
    position: absolute;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}